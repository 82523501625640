import { type } from '../../../@Util/Serialization/Serialization';
import { ConstraintNode } from './ConstraintNode';
import { observable } from 'mobx';
import { Entity } from './Entity';
import { EntityEvent } from './EntityEvent';
import { Aggregate } from '../../../@Component/Domain/DataObject/Model/Aggregate';
import { EntityFieldPath } from '../../../@Component/Domain/Entity/Path/@Model/EntityFieldPath';
import { Comparator } from '../../../@Component/Domain/DataObject/Model/Comparator';
import Value from '../../Automation/Value/Value';
import { Selection } from '../../Selection/Model/Selection';
import { getSelectionDescriptor } from '../../Selection/Api/getSelectionDescriptor';
import { EntityNode } from './EntityNode';

@type('AggregateSelectionComparisonConstraintNode')
export class AggregateSelectionComparisonConstraintNode extends ConstraintNode
{
    // ------------------- Persistent Properties --------------------

    @observable.ref selection: Selection;
    @observable aggregate: Aggregate;
    @observable.ref aggregateFieldPath: EntityFieldPath;
    @observable comparator: Comparator;
    @observable.ref value: Value<any, any>;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------


    constructor(
        selection: Selection,
        aggregate: Aggregate,
        aggregateFieldPath: EntityFieldPath,
        comparator: Comparator,
        value: Value<any, any>
    )
    {
        super();

        this.selection = selection;
        this.aggregate = aggregate;
        this.aggregateFieldPath = aggregateFieldPath;
        this.comparator = comparator;
        this.value = value;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getDependencies(): EntityNode[]
    {
        return [
            ...super.getDependencies(),
            ...this.selection.getDependencies(),
        ];
    }

    hashCode(): string
    {
        return `${super.hashCode()},${this.selection.hashCode()},${this.aggregate}.${this.aggregateFieldPath.id},${this.comparator},${this.value.getId()}`;
    }

    equals(node: AggregateSelectionComparisonConstraintNode): boolean
    {
        return super.equals(node)
            && this.selection.equals(node.selection)
            && this.aggregate === node.aggregate
            && this.aggregateFieldPath.equals(node.aggregateFieldPath)
            && this.comparator === node.comparator
            && this.value.equals(node.value);
    }

    isAffectedBy(event: EntityEvent): boolean
    {
        return false;
    }

    descriptor()
    {
        return {
            type: 'AggregateSelectionComparison',
            selection: getSelectionDescriptor(this.selection),
            aggregate: Aggregate[this.aggregate],
            aggregateFieldPath: this.aggregateFieldPath.descriptor,
            comparator: Comparator[this.comparator],
            value: this.value.toDescriptor(),
        };
    }

    // ----------------------- Private logic ------------------------
}
