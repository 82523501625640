import Right from './Right';
import { observable } from 'mobx';
import { EntityType } from '../../../../@Api/Model/Implementation/EntityType';
import { EntityField } from '../../../../@Api/Model/Implementation/EntityField';
import { EntityRelationshipDefinition } from '../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import { loadModuleDirectly } from '../../../../@Util/DependencyInjection/index';
import { EntityTypeStore } from '../../Entity/Type/EntityTypeStore';
import Feature from './Feature';
import { ExportingFeature } from './Features';
import AutomationDependencyContext from '../../../../@Api/Automation/AutomationDependencyContext';
import ParameterDictionary from '../../../../@Api/Automation/Parameter/ParameterDictionary';
import Parameter from '../../../../@Api/Automation/Parameter/Parameter';
import EntityValueType from '../../../../@Api/Automation/Value/Type/EntityValueType';
import { RoleParams } from './RoleParams';
import getTypes from "../../Entity/Type/Api/getTypes";
import localizeText from "../../../../@Api/Localization/localizeText";

export type RoleType = 'AdministratorBaseRole' | 'UserBaseRole' | 'UserDefaultRole';

export type Permission = 'Granted' | 'ConditionallyGranted' | 'Denied' | 'Inherited';

export type Privilege = 'canRead' | 'canCreate' | 'canUpdate' | 'canDelete' | 'canExport';

export const AllPrivileges: Privilege[] = ['canRead', 'canCreate', 'canUpdate', 'canDelete', 'canExport' ];

export const BasePermission: Permission = 'Granted';

export default class Role
{
    // ------------------------- Properties -------------------------

    @observable.shallow rightByType: Map<EntityType, Right>;
    @observable.shallow rightByField: Map<EntityField, Right>;
    @observable.shallow rightByRelationshipType: Map<EntityRelationshipDefinition, Right>;
    @observable.shallow features: Feature[];

    // ------------------------ Constructor -------------------------

    constructor(rightByType: Map<EntityType, Right> = new Map(),
                rightByField: Map<EntityField, Right> = new Map(),
                rightByRelationshipType: Map<EntityRelationshipDefinition, Right> = new Map(),
                features: Feature[] = [])
    {
        this.rightByType = rightByType;
        this.rightByField = rightByField;
        this.rightByRelationshipType = rightByRelationshipType;
        this.features = features;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getTypePermission(
        entityType: EntityType,
        privilege: Privilege
    ) : Permission
    {
        if (entityType)
        {
            const right = this.rightByType.get(entityType);

            const permission = right
                ? right.getPermission(privilege)
                : 'Inherited';

            if (permission === 'Inherited')
            {
                return this.getTypePermission(
                    entityType.parentType,
                    privilege);
            }
            else
            {
                return permission;
            }
        }
        else
        {
            return BasePermission;
        }
    }

    getTypeRight(
        entityType: EntityType
    ) : Right
    {
        if (entityType)
        {
            const right = this.rightByType.get(entityType);

            return right === undefined
                ? this.getTypeRight(entityType.parentType)
                : right;
        }
        else
        {
            return undefined;
        }
    }

    private isTypePermissionGranted(
        entityType: EntityType,
        privilege: Privilege
    ) : boolean
    {
        const permission = this.getTypePermission(entityType, privilege);

        return permission === 'Granted'
            || permission === 'ConditionallyGranted';
    }

    getFieldPermission(
        field: EntityField,
        privilege: Privilege
    ) : Permission
    {
        const right = this.rightByField.get(field);

        const permission = right
            ? right.getPermission(privilege)
            : 'Inherited';

        if (permission === 'Inherited')
        {
            return BasePermission;
        }
        else
        {
            return permission;
        }
    }

    private isFieldPermissionGranted(
        field: EntityField,
        privilege: Privilege
    ) : boolean
    {
        const permission = this.getFieldPermission(field, privilege);

        return permission === 'Granted'
            || permission === 'ConditionallyGranted';
    }

    getRelationshipTypePermission(
        relationshipType: EntityRelationshipDefinition,
        privilege: Privilege
    ) : Permission
    {
        const right = this.rightByRelationshipType.get(relationshipType);

        const permission = right
            ? right.getPermission(privilege)
            : 'Inherited';

        if (permission === 'Inherited')
        {
            return BasePermission;
        }
        else
        {
            return permission;
        }
    }

    private isRelationshipTypePermissionGranted(
        relationshipType: EntityRelationshipDefinition,
        privilege: Privilege
    ) : boolean
    {
        const permission = this.getRelationshipTypePermission(relationshipType, privilege);

        return permission === 'Granted'
            || permission === 'ConditionallyGranted';
    }

    isPermissionGrantedForType(
        entityType: EntityType,
        privilege: Privilege,
        checkRead: boolean = true
    ) : boolean
    {
        switch (privilege)
        {
            case 'canRead':
            case 'canCreate':
                return this.isTypePermissionGranted(entityType, privilege);
            case 'canUpdate':
            case 'canDelete':
                return this.isTypePermissionGranted(entityType, privilege)
                    && (checkRead
                        ? this.isTypePermissionGranted(entityType, 'canRead')
                        : true);
            case 'canExport':
                return this.isTypePermissionGranted(entityType, privilege)
                    && (checkRead
                        ? this.isTypePermissionGranted(entityType, 'canRead')
                        : true);
            default:
                return false;
        }
    }

    isPermissionGrantedForField(
        entityType: EntityType,
        field: EntityField,
        privilege: Privilege
    ) : boolean
    {
        switch (privilege)
        {
            case 'canRead':
                return this.isFieldPermissionGranted(field, privilege)
                    && this.isPermissionGrantedForType(entityType, 'canRead');
            case 'canCreate':
            case 'canUpdate':
            case 'canDelete':
                return this.isFieldPermissionGranted(field, 'canRead')
                    && this.isFieldPermissionGranted(field, privilege)
                    && this.isPermissionGrantedForType(entityType, 'canUpdate');
            case 'canExport':
                return this.isFieldPermissionGranted(field, 'canRead')
                    && this.isFieldPermissionGranted(field, privilege)
                    && this.isPermissionGrantedForType(entityType, 'canExport');
            default:
                return false;
        }
    }

    isPermissionGrantedForRelationshipTypeFromSide(
        entityType: EntityType,
        relationshipType: EntityRelationshipDefinition,
        isParent: boolean,
        privilege: Privilege
    ) : boolean
    {
        const relatedType = relationshipType.getEntityType(isParent);

        return this.isPermissionGrantedForRelationshipType(
            relationshipType,
            isParent ? relatedType : entityType,
            isParent ? entityType : relatedType,
            privilege
        );
    }

    isPermissionGrantedForRelationshipType(
        relationshipType: EntityRelationshipDefinition,
        parentEntityType: EntityType,
        childEntityType: EntityType,
        privilege: Privilege
    ) : boolean
    {
        switch (privilege)
        {
            case 'canRead':
                return this.isRelationshipTypePermissionGranted(relationshipType, privilege)
                    && this.isPermissionGrantedForType(parentEntityType, 'canRead')
                    && this.isPermissionGrantedForType(childEntityType, 'canRead');
            case 'canCreate':
            case 'canUpdate':
            case 'canDelete':
                if (this.isRelationshipTypePermissionGranted(relationshipType, 'canRead')
                    && this.isRelationshipTypePermissionGranted(relationshipType, privilege))
                {
                    const isParentTypeMutable = this.isPermissionGrantedForType(parentEntityType, 'canUpdate');
                    const isChildTypeMutable = this.isPermissionGrantedForType(childEntityType, 'canUpdate');

                    if ((isParentTypeMutable || isChildTypeMutable))
                    {
                        if (isParentTypeMutable && !isChildTypeMutable)
                        {
                            return relationshipType.isPlural(true);
                        }
                        else if (!isParentTypeMutable && isChildTypeMutable)
                        {
                            return relationshipType.isPlural(false);
                        }
                        else
                        {
                            return true;
                        }
                    }
                    else
                    {
                        return false;
                    }
                }
                else
                {
                    return false;
                }
            case 'canExport':
                return this.isRelationshipTypePermissionGranted(relationshipType, 'canRead')
                    && this.isRelationshipTypePermissionGranted(relationshipType, privilege)
                    && this.isPermissionGrantedForType(parentEntityType, 'canExport')
                    && this.isPermissionGrantedForType(childEntityType, 'canExport');
            default:
                return false;
        }
    }

    isPermissionGrantedForFeature(feature: Feature)
    {
        return this.features.some(
            featureToCheck =>
                featureToCheck.id === feature.id);
    }

    isFeatureEnabled(feature: Feature) : boolean
    {
        return this.features.some(
            checkFeature =>
                checkFeature.id === feature.id
        );
    }

    isExportingEnabled() : boolean
    {
        return this.isFeatureEnabled(ExportingFeature);
    }

    toDescriptor(): any
    {
        return {
                typeRights:
                    Array.from(this.rightByType.entries())
                        .filter(
                            ([, right]) =>
                                !AllPrivileges.every(privilege => right.getPermission(privilege) === 'Inherited'))
                        .map(
                            ([type, right]) =>
                                ({
                                    typeId: type.id,
                                    right: right.toDescriptor()
                                })),
                fieldRights:
                    Array.from(this.rightByField.entries())
                        .filter(
                            ([, right]) =>
                                !AllPrivileges.every(privilege => right.getPermission(privilege) === 'Inherited'))
                        .map(
                            ([field, right]) =>
                                ({
                                    fieldId: field.id,
                                    right: right.toDescriptor()
                                })),
                relationshipTypeRights:
                    Array.from(this.rightByRelationshipType.entries())
                        .filter(
                            ([, right]) =>
                                !AllPrivileges.every(privilege => right.getPermission(privilege) === 'Inherited'))
                        .map(
                            ([relationshipType, right]) =>
                                ({
                                    relationshipTypeId: relationshipType.id,
                                    right: right.toDescriptor()
                                })),
                features:
                    this.features.map(feature => feature.toDescriptor())
        };
    }

    static async fromDescriptor(descriptor: any): Promise<Role>
    {
        const entityTypeStore = loadModuleDirectly(EntityTypeStore);

        const role = new Role(
            await Role.getTypeRightsFromDescriptor(
                descriptor,
                entityTypeStore
            ),
            await Role.getFieldRightsFromDescriptor(
                descriptor,
                entityTypeStore
            ),
            await Role.getRelationshipRightsFromDescriptor(
                descriptor,
                entityTypeStore
            ),
            (descriptor.features || [])
                .map(
                    feature =>
                        Feature.fromDescriptor(feature)
                )
        );

        return Promise.resolve(role);
    }

    // ----------------------- Private logic ------------------------

    private static async getTypeRightsFromDescriptor(
        descriptor: any,
        entityTypeStore: EntityTypeStore
    )
    {
        const typeRights = new Map<EntityType, Right>();

        await Promise.all(
            descriptor
                .typeRights
                .map(
                    async (typeRight) =>
                    {
                        const entityType = entityTypeStore.getTypeById(typeRight.typeId);

                        if (entityType)
                        {
                            const automationDependencyContext = new AutomationDependencyContext(
                                Role.getEntityTypeParameterDictionary(entityType)
                            );

                            const right =  await Right.fromDescriptor(
                                typeRight.right,
                                automationDependencyContext
                            );

                            typeRights.set(entityType, right);
                        }
                    }
                )
            );

        return typeRights;
    }

    static async getFieldRightsFromDescriptor(
        descriptor: any,
        entityTypeStore: EntityTypeStore
    )
    {
        const fieldRights = new Map<EntityField, Right>();

        await Promise.all(
            descriptor.fieldRights
                .map(
                    async (fieldRight) =>
                    {
                        const field = entityTypeStore.getFieldById(fieldRight.fieldId);

                        if (field
                            && field.entityType !== undefined)
                        {
                            const entityType = field.entityType;
                            const automationDependencyContext = new AutomationDependencyContext(
                                Role.getEntityTypeParameterDictionary(entityType)
                            );

                            const right = await Right.fromDescriptor(
                                fieldRight.right,
                                automationDependencyContext
                            );

                            fieldRights.set(field, right);
                        }
                    }
                )
        );

        return fieldRights;
    }

    static async getRelationshipRightsFromDescriptor(
        descriptor: any,
        entityTypeStore: EntityTypeStore
    ): Promise<Map<EntityRelationshipDefinition, Right>>
    {
        const relationshipTypeRights = new Map<EntityRelationshipDefinition, Right>();

        await Promise.all(
            descriptor.relationshipTypeRights
                .map(
                    async (relationshipTypeRight) =>
                    {
                        const relationshipType = entityTypeStore.getRelationshipDefinitionById(
                            relationshipTypeRight.relationshipTypeId
                        );

                        if (relationshipType
                            && relationshipType.parentEntityType
                            && relationshipType.childEntityType
                        )
                        {
                            const automationDependencyContext = new AutomationDependencyContext(
                                Role.getRelationshipTypeParameterDictionary(
                                    relationshipType
                                )
                            );

                            const right = await Right.fromDescriptor(
                                relationshipTypeRight.right,
                                automationDependencyContext
                            );

                            relationshipTypeRights.set(relationshipType, right);
                        }
                    }
                )
        );

        return relationshipTypeRights;
    }

    static getEntityTypeParameterDictionary(
        entityType: EntityType
    )
    {
        return new ParameterDictionary([
            new Parameter(
                RoleParams.Entity,
                new EntityValueType(entityType),
                true,
                entityType.getName()
            ),
            new Parameter(
                RoleParams.Me,
                new EntityValueType(
                    getTypes().Relationship.Person.Contact.Employee.Type
                ),
                true,
                localizeText('Generic.Myself', 'Mijzelf')
            )
        ]);
    }

    static getRelationshipTypeParameterDictionary(
        relationshipType: EntityRelationshipDefinition
    )
    {
        return new ParameterDictionary([
            new Parameter(
                RoleParams.ParentEntity,
                new EntityValueType(relationshipType.parentEntityType),
                true,
                relationshipType.parentEntityType.getName()
            ),
            new Parameter(
                RoleParams.ChildEntity,
                new EntityValueType(relationshipType.childEntityType),
                true,
                relationshipType.childEntityType.getName()
            ),
            new Parameter(
                RoleParams.Me,
                new EntityValueType(
                    getTypes().Relationship.Person.Contact.Employee.Type
                ),
                true,
                localizeText('Generic.Myself', 'Mijzelf')
            )
        ]);
    }
}
